import { ITransaction } from '@/views/Payments';

export type PaymentStatus = 'not_paid' | 'paid' | 'paid_partially';

export const getPaymentStatus = (
    transaction: ITransaction,
    enableExternalPaidFlagInTableView?: boolean,
): PaymentStatus => {
    if (!transaction) {
        return 'not_paid';
    }
    let paidAmount = 0;
    let customerCommission = 0;
    if (transaction.paymentRecord && transaction.paymentRecord[0]) {
        transaction.paymentRecord.map((item) => {
            paidAmount += Number(item.paid_amount);
            customerCommission += item.customer_commission;
            return true;
        });
    }
    if (
        (transaction.paid && enableExternalPaidFlagInTableView) ||
        paidAmount - customerCommission >= Number(transaction.bill_amount)
    ) {
        return 'paid';
    }

    if (paidAmount > 0) {
        return 'paid_partially';
    }

    return 'not_paid';
};
